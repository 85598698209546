<template>
    <div class="loader_area3" v-if="loader"><quote-loader/></div>
    <div class="profile_notes" v-else>
        <div class="title mb-3">
            <h3>Notes</h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <!-- <page-filter v-model="params.per_page" /> -->
                <button :class="selectedNotes.length ? 'delete active' : 'delete'" @click="handleDeleteNotes()"><i class="fas fa-trash-alt"></i></button>
                <button :class="selectedNotes.length ? 'pin active' : 'pin'"><i class="fas fa-thumbtack"></i></button>
            </div>
        </div>
        <div class="note_editor" :class="{'show' : show_editor}">
            <div class="placeholder" @click="show_editor = !show_editor;">
                Add a note here... 
                <!-- <img src="@/assets/images/expand-alt.svg"> -->
            </div>
            <div class="editor">
                <h4>
                    Add Note:
                    <!-- <button type="button" class="shrink_btn ml-auto" @click="show_editor = false;"><img src="@/assets/images/expand-alt.svg"></button> -->
                    <!-- <div class="dropdown">
                        <i class="fas fa-ellipsis-v" @click="noteDropdown = !noteDropdown" v-click-outside="closeNoteDropdown"></i>
                        <div class="drp_wrapper" v-if="noteDropdown" :class="{ active : noteDropdown }">
                            <ul>
                                <li @click="resetEditor">Reset to default message</li>
                                <li @click="templateLibrary = !templateLibrary; noteDropdown = false;" :class="{ active : templateLibrary }">Import Template</li>
                            </ul>
                        </div>
                    </div> -->
                </h4>
                <Form @submit="handleAddNote" v-slot="{ errors }" ref="noteForm" class="note_form">
                    <div class="editorWpr" :class="{ 'has-error': errors.note }">
                        <Field autocomplete="off" name="note" v-model="form.note" :class="{ 'has-error': errors.note }" rules="required">
                            <redactor v-model="form.note" name="note" :default-value="editorDefaultValue" :reset-watcher="resetWatcher" :has-import-template="true" :import-template-handler="toggleTemplateLibrary"/>
                        </Field>
                    </div>
                    <div class="err_message">
                        <ErrorMessage name="note" class="text-danger" />
                    </div>
                    <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="content" />
                    <div class="action_wpr mt-3 mb-0 p-0 border-0">
                        <button :disabled="noteLoader" type="button" class="btn cancel_btn" @click="closeNoteEditor()">Cancel</button>
                        <button :disabled="noteLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="noteLoader"></i>{{ noteLoader ? ' Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="notes_wpr">
            <ul class="card_listing" v-if="notes.total">
                <li  v-for="(note, key) in notes.data" :key="key">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="note">
                        <h4 @click="actionHandler(note, 'editNote')" class="pointer"><img src="@/assets/images/note.svg"> Notes:</h4>
                        <div class="card_content">
                            <p><span v-html="note.notes"></span></p>
                        </div>
                        <div class="card_footer">
                            <!-- <h5>Created on:</h5> -->
                            <span class="date">{{ `${moment.utc(note.created_at).local().format('ll | LT')}` }}</span>
                        </div>
                        <label :for="`note-${key}`" class="checkbox">
                            <input type="checkbox" :id="`note-${key}`" :value="note.id" v-model="selectedNotes" hidden>
                            <span><i class="fas fa-check"></i></span>
                        </label>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list" v-else>
                <img src="@/assets/images/empty_state.svg">
                <h4>No Notes Available</h4>
            </div>
        </div>
        <div class="pagination" v-if="notes.total && !editNote">
            <pagination v-model="params.page" :pages="notes.last_page" @update:modelValue="handlePagination" />
        </div>
    </div>
    <div class="modal" v-if="editNote">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Edit Note</h1>
                </div>
                <button class="close_btn left_out" @click="editNote = false"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <edit-note :closeModal="closeModal" :note="editForm" title="Edit Note"/>
            </div>
        </div>
    </div>
    <!-- <div v-if="editNote" class="edit_modal">
        <div class="edit_section p-0">
            <div class="form_body">
                <h2 class="title_header">Edit Note<button class="close_btn" @click="editNote = false"><i class="fas fa-times"></i></button></h2>
                <div class="form_content">
                    <edit-note :closeModal="closeModal" :note="editForm" title="Edit Note"/>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const EditNote = defineAsyncComponent(() => import('@/pages/contact/components/profile/EditNote'))
    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import moment from 'moment'

    export default{
        name: 'Profile Notes',

        data () {
            return {
                editNote: false,
                selectedNotes: [],
                actions: [
                    {
                        icon: "fa-edit",
                        label: "Edit",
                    }
                ],
                form: {
                    note: '',
                    contact_id: '',
                },
                params: {
                    page: 1,
                    per_page: 6,
                },
                moment,
                templateLibrary: false,
                noteDropdown: false,
                editForm: {
                    id: '',
                    notes: '',
                    contact_id: '',
                },
                isTyping: false,
                editorDefaultValue: '<p></p>',
                resetWatcher: 0,
                show_editor: false,
            }
        },

        props: {
            contact: Object,
        },

        watch: {
            'params.per_page' (perPage) {
                const vm = this;

                vm.params.page = 1,
                vm.getNotes(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getNotes(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getNotes(vm.params);
                        }
                    }
                }
            },

            notes () {
                const vm = this;
                vm.selectedNotes = [];
            }
        },

        components:{
            Form,
            Field,
            EditNote,
            ProfileCard,
            ErrorMessage,
            TemplateLibrary,
        },

        computed: {
            ...mapState({
                loader: state => state.contactModule.contactProfileLoader,
                notes: state => state.contactModule.notes,
                noteLoader: state => state.contactModule.noteLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id ? vm.contact.id : '';
            vm.resetForm();
            vm.getNotes(vm.params);
        },

        methods:{
            ...mapActions({
                getNotes: 'contactModule/getNotes',
                addNote: 'contactModule/addNote',
                deleteNotes: 'contactModule/deleteNotes',
            }),

            closeModal() {
                const vm = this;

                vm.editNote = false;
            },

            actionHandler (note, action) {
                const vm = this;

                vm.editForm.id          = note.id
                vm.editForm.notes       = note.notes
                vm.editForm.contact_id  = vm.contact.id ? vm.contact.id : '',
                vm.editNote = true;
            },

            resetForm () {
                const vm = this;

                vm.form = {
                                note: '',
                                contact_id: vm.contact.id ? vm.contact.id : '',
                            };

                const noteForm = vm.$refs['noteForm'];

                if (noteForm) {
                    noteForm.resetForm();
                }

                vm.resetWatcher += 1;
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getNotes(vm.params);
            },

            handleAddNote (params, { setFieldError, resetForm }) {
                const vm = this;
                vm.form.setFieldError = setFieldError;
                vm.addNote(vm.form).then((result) => {
                    if (result) {
                        vm.resetForm();
                        vm.getNotes(vm.params);
                        vm.show_editor = false;
                    }
                });
            },

            toggleTemplateLibrary(){
                const vm = this;

                vm.templateLibrary = !vm.templateLibrary;
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.form.note = template.content;
                vm.editorDefaultValue = template.content;
            },

            handleDeleteNotes () {
                const vm = this;

                const params = { ids: vm.selectedNotes, contact_id: vm.form.contact_id}
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these notes', 'Delete')
                      option.preConfirm = function() {
                          return vm.deleteNotes(params).then((result) => {
                              vm.selectedNotes = [];
                              vm.getNotes(vm.params);
                          });
                       };
                Swal.fire(option);
            },

            closeNoteDropdown () {
                const vm = this;

                vm.noteDropdown = false;
            },

            resetEditor () {
                const vm = this;

                vm.form.note = '';
                vm.resetWatcher += 1;

                const noteForm = vm.$refs['noteForm'];

                if (noteForm) {
                    noteForm.resetForm();
                }
            },

            closeNoteEditor(){
                const vm = this;

                vm.show_editor = false;
                vm.resetEditor();
            }
        }
    }
</script>
<style scoped>
    .err_message {
        margin-top: 10px !important;
    }
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .note_editor{
        border-radius: 25px;
        padding: 15px 20px;
        background: #fff;
        max-width: 700px;
        margin: 0 auto 20px auto;
        transition: all 0.3s ease-in-out;
    }
    .note_editor.show{
        border-radius: 8px;
        padding: 20px;
    }
    .note_editor .placeholder{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: text;
    }
    .note_editor .placeholder img{
        max-height: 16px;
        width: auto;
        transform: rotate(180deg);
        filter: grayscale(1);
        opacity: 0.3;
        cursor: pointer;
    }
    .note_editor .editor, .note_editor.show .placeholder{
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .note_editor.show .editor, .note_editor .placeholder{
        max-height: max-content;
        overflow: visible;
    }
    .global_setting .note_editor .editorWpr :deep(.redactor-styles){
        min-height: 120px !important;
        height: auto;
    }
    .note_editor .action_wpr .btn{
        height: 30px;
    }
    .note_editor .editor h4 .shrink_btn img{
        border: 0;
        border-radius: 0;
        max-height: 16px;
        width: auto;
        filter: grayscale(1);
        opacity: 0.3;
        cursor: pointer;
    }
    .profile_notes .card_listing{
        gap: 0;
        margin: 0 -15px;
    }
    .profile_notes .card_listing li{
        flex: 0 0 33.333%;
        min-width: 180px;
        padding: 15px;
    }
    .profile_notes .title .right_side{
        gap: 0;
    }
    :deep(.note_editor .action_wpr){
        background: transparent !important;
    }
    .note_editor h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-weight: 500;
    }
    .edit_modal :deep(.editorWpr .redactor-styles){
        height: auto;
    }
    .edit_modal :deep(form > .action_wpr){
        border: 0;
        background: transparent;
    } 
    .note_form .edit_section{
        background: #fafafb;
    }
    :deep(.note_card p){
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
    }
    @media(max-width: 1199px){
        .profile_notes .card_listing li{
            flex: 0 0 calc(50% - 10px);
        }
    }
    @media(max-width: 890px){
        .note_editor{
            width: 100%;
            position: static;
        }
    }
    @media(max-width: 499px){
        .profile_notes .card_listing{
            flex: 0 0 100%;
        }
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .global_setting .note_editor .action_wpr {
            padding: 0 !important;
        }
    }
</style>
